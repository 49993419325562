import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import DrawingCanvas from './DrawingCanvas.js'
import MyDoodles from './MyDoodles';
import Gallery from './Gallery';
import { GuidProvider, useGuid } from './GuidContext';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from "react-router-dom";
import shareIcon from './shareIcon.png'; // Adjust the path as necessary
import HelpModal from './HelpModal.js'
import squiggleLine from './squiggleLine.png'
import straightLine from './straightLine.png'
import undoIcon from './undoIcon.png'
import sampleIcon from './sampleIcon.png'
import questionIcon from './question.png'
function App() {


  return (

    <GuidProvider>
      <GuidComponent />
    </GuidProvider>

  );
}


function GuidComponent() {
  const [isPublished, setIsPublished] = useState(() => {
    // Initially set isPublished based on local storage to retain state across refreshes
    return localStorage.getItem('isPublished') === 'true';
  }); const saveDrawingRef = useRef(null);
  const { guid } = useGuid();
  const [dailySubject, setDailySubject] = useState('');
  const [color, setColor] = useState('#000000')
  const shareDrawingRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showSubject, setShowSubject] = useState(true);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  // Callback function to handle publish event
  const onPublish = () => {
    if (saveDrawingRef.current && typeof saveDrawingRef.current === 'function') {
      saveDrawingRef.current();
      setIsPublished(true); // Assuming you might set this upon successful publication
      localStorage.setItem('isPublished', 'true'); // Save to local storage
    }
  };


  // Watch for changes in isPublished to update local storage
  useEffect(() => {
    localStorage.setItem('isPublished', isPublished.toString());
  }, [isPublished]);

  const onShare = () => {
    if (shareDrawingRef.current) {
      shareDrawingRef.current(); // This will call the shareDrawing function in DrawingCanvas
    }
  };

  const disabledButtonStyle = {
    ...publishButtonStyle, // Spread the existing button styles to maintain the base look
    opacity: 0.5, // Make it visually appear disabled
    cursor: 'not-allowed', // Change cursor to indicate it's not clickable
    pointerEvents: 'none', // Prevent clicking
  };




  useEffect(() => {

    const checkDevice = () => {
      setIsMobile(/Android|webOS|iPad|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
      //setIsMobile(true);

    };

    // Check the device on component mount
    checkDevice();

    // Fetch the daily subject from AWS
    fetch('https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com/getDailySubject')
      .then(response => response.json())
      .then(data => setDailySubject(data.subject))
      .catch(error => console.error("Failed to fetch daily subject:", error));
  }, []);

  // Early return if not on a mobile device

  if (!isMobile) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        Welcome to The Daily Doodle. This website is designed to be used on mobile devices.
      </div>
    );
  }


  return (
    <Router>
      <div>
        <nav class='navigation' style={navStyle}>
          <ul style={ulStyle}>
            <li style={liStyle} onClick={() => setIsHelpModalOpen(true)}>
              <img src={questionIcon} alt="?" style={questionStyle} />
            </li>
            <li style={liStyle}>
              <Link to="/">Canvas</Link>
            </li>
            <li style={isPublished ? liStyle : { ...liStyle, ...disabledLinkStyle }}>
              <Link to={isPublished ? "/Gallery" : "#"} >Gallery</Link>
            </li>
            <li style={liStyle}>
              <Link to="/my-doodles">My Doodles</Link>
            </li>
            <li style={liStyle} onClick={onShare}>
              <button style={buttonStyle}>
                <img src={shareIcon} alt="Share" style={iconStyle} />
              </button>
            </li>
            <li style={liStyle}>
              {/* Modify this part to always render the button but conditionally disable it */}
              <button
                onClick={!isPublished ? onPublish : undefined} // Only allow click when not published
                style={isPublished ? disabledButtonStyle : publishButtonStyle} // Use disabled style if published
              >
                {isPublished ? 'Published' : 'Publish'}
              </button>
            </li>
          </ul>
        </nav>
        {/* Displaying the daily subject */}
        {dailySubject && showSubject && <div style={subjectStyle}>Today's Subject: {dailySubject}</div>}
        <Routes>
          <Route path="/" element={<DrawingCanvas color={color} setColor={setColor} mySaveDrawingRef={saveDrawingRef} isPublished={isPublished} setIsPublished={setIsPublished} myShareDrawingRef={shareDrawingRef} setShowSubject={setShowSubject} />} />
          <Route path="/Gallery" element={<Gallery setShowSubject={setShowSubject} />} />
          <Route path="/my-doodles" element={<MyDoodles setShowSubject={setShowSubject} />} />
        </Routes>

        <HelpModal open={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)}>
          <div>

            <div className="icon-container">
              <img src={undoIcon} alt="Undo Icon" />
              <div className="icon-description">
                <strong>Undo Icon</strong> - The Undo icon allows you to revert your last action on the canvas.
              </div>
            </div>

            <div className="icon-container">
              <div className="icon-description">
                <strong>Color Sampling</strong> - Touch the eyedropper and then touch the canvas to select the color at that coordinate.
              </div>
            </div>

            <div className="icon-container">
              <div className="icon-description">
                <strong>Alpha Icons</strong> - The Alpha icons represent different levels of opacity for the selected color. The available alpha values are 10%, 50%, and 100%.
              </div>
            </div>

            <div className="icon-container">
              <img src={straightLine} alt="Straight Line Icon" />
              <img src={squiggleLine} alt="Squiggle Line Icon" />
              <div className="icon-description">
                <strong>Line Mode Icons</strong> - The Line Mode icons toggle between drawing straight lines and freehand (squiggly) lines. This feature offers precision or freedom in your drawing style.
              </div>
            </div>
          </div>
        </HelpModal>
      </div>
    </Router>

  );
}

// Styles
const navStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '30px',
  backgroundColor: '#f4f4f4',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
};

const ulStyle = {
  listStyleType: 'none',
  margin: '5px',
  padding: 0,
  display: 'flex'
};

// Updated liStyle to include flex display and align items
const liStyle = {
  display: 'flex',
  alignItems: 'center', // This vertically centers the content
  justifyContent: 'center', // This horizontally centers the content
  height: '50px', // Example height, adjust as needed
  marginRight: '10px'
};

// Adjust buttonStyle to ensure the button has the same height
const buttonStyle = {
  display: 'inline-flex', // Keeps button inline with content and uses flexbox for alignment
  alignItems: 'center',
  justifyContent: 'center',
  height: '30px', // Example smaller height, adjust as needed
  background: 'none',
  border: 'none',
  padding: '0',
  cursor: 'pointer',
};


const disabledLinkStyle = {
  opacity: 0.5,
  pointerEvents: 'none',
};
const iconStyle = {
  width: '24px', // Adjust the size as necessary
  height: 'auto',
};

const questionStyle = {
  width: '18px',
  height: 'auto',
}

const publishButtonStyle = {
  backgroundColor: '#4CAF50', // Example button color, adjust as needed
  color: 'white',
  padding: '5px',
  textAlign: 'center',
  textDecoration: 'none',
  display: 'inline-block',
  fontSize: '15px',
  margin: '5px',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '4px',
};

// Style for the daily subject
const subjectStyle = {
  textAlign: 'center',
  marginTop: '10px',
  fontSize: '18px'
};

export default App;