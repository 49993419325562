import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useGuid } from './GuidContext';
import { ColorPalette } from './ColorPalette';  // Import the ColorPalette component
import RatingComponent from './MyRatingComponent';
import ConfirmDialog from './ConfirmDialog'; // Import the ConfirmDialog component

const DrawingCanvas = ({ color, setColor, mySaveDrawingRef, isPublished, setIsPublished, myShareDrawingRef, setShowSubject }) => {
    const [activeLayer, setActiveLayer] = useState(0);
    const canvasRef1 = useRef(null);
    const canvasRef2 = useRef(null);
    const canvasRef3 = useRef(null);
    const canvasRefs = useMemo(() => [canvasRef1, canvasRef2, canvasRef3], []);
    const contexts = useRef([]);
    const mainCanvasRef = useRef(null);
    const mainContext = useRef(null);
    const workCanvasRef = useRef(null);
    const workContext = useRef(null);
    const beginDrawLayerContext = useRef(null);
    const beginDrawLayerCanvasRef = useRef(null);
    const { guid } = useGuid();
    const [isDrawing, setIsDrawing] = useState(false);
    const [touchPoints, setTouchPoints] = useState([]);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [brushSize, setBrushSize] = useState(2);
    const [alpha, setAlpha] = useState(1.0);
    const [eraseMode, setEraseMode] = useState(false); // New state for erase mode
    const [lastPoint, setLastPoint] = useState(null);
    const [dailySubject, setDailySubject] = useState(''); // State for storing the daily subject
    const [isCanvasModified, setIsCanvasModified] = useState(false);
    const [lineMode, setLineMode] = useState('squiggle');
    const [lineStart, setLineStart] = useState({ x: 0, y: 0 });
    // Example rating state - you would replace this with your actual logic to obtain the rating
    const [rating] = useState(0.0); // Example rating value
    const [averageRating] = useState(0.0); // Example average rating value
    const [numRaters] = useState(0); // Example number of raters
    const touchHoldTimerRef = useRef(null); // Ref for holding the timer ID
    const [history, setHistory] = useState([]);
    const [historyIndex, setHistoryIndex] = useState(-1);
    const offScreenCanvasRef = useRef(null);
    const offScreenContext = useRef(null);
    const [sampleMode, setSampleMode] = useState(false);
    const [hasMoved, setHasMoved] = useState(false);
    const [recentResize, setRecentResize] = useState(false);


    // Refactor the localStorage keys to be guid specific
    const getLocalStorageKey = useCallback((key) => `${guid}-${key}`, [guid]);

    // New states for pinch-to-zoom
    const [scale, setScale] = useState(1);

    // State to determine if the splash screen should be shown
    const [showSplash, setShowSplash] = useState(false);


    // State for managing the signature prompt
    const [showSignaturePrompt, setShowSignaturePrompt] = useState(false);
    const [signature, setSignature] = useState('');
    const [localSignature, setLocalSignature] = useState('');

    // State and ref for tap tracking
    const [tapCount, setTapCount] = useState(0);
    const tapTimerRef = useRef(null);
    const lastTapPositionRef = useRef({ x: 0, y: 0 });

    // State for showing the confirmation dialog
    const [showConfirm, setShowConfirm] = useState(false);

    const getYesterdayDateInCentralTime = () => {
        const todayInCST = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Chicago" }));
        const yesterdayInCST = new Date(todayInCST);
        yesterdayInCST.setDate(todayInCST.getDate() - 1);

        return yesterdayInCST.toLocaleString("en-US", { timeZone: "America/Chicago" }).split(',')[0];
    };


    // Clear local storage and reload page
    const clearLocalStorageAndReload = () => {
        const isLocal = window.location.hostname === '192.168.86.38';
        if (isLocal) {

            setIsPublished(false);
            // Set lastVisitDate to yesterday in CST
            const yesterdayCST = getYesterdayDateInCentralTime();
            const lastVisitDateKey = getLocalStorageKey('lastVisitDate');
            localStorage.setItem(lastVisitDateKey, yesterdayCST);
        }

        for (let i = 1; i <= 3; i++) {
            localStorage.removeItem(getLocalStorageKey(`layer${i}`));
        }

        window.location.reload();
    };



    const handleSignatureSubmit = () => {
        if (signature) {
            localStorage.setItem('signature', signature);
        }
        const base64Image = getBase64Image();

        saveDrawing(base64Image, guid, true, dailySubject, signature || localSignature);
        setIsPublished(true);
        setShowSignaturePrompt(false);
    };

    useEffect(() => {
        if (recentResize) {
            const timer = setTimeout(() => {
                setRecentResize(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [recentResize]);

    // save to local storage every 10 seconds
    useEffect(() => {
        const saveLayersToLocalStorage = () => {
            canvasRefs.forEach((canvasRef, index) => {
                const canvas = canvasRef.current;
                if (canvas) {
                    const dataUrl = canvas.toDataURL();
                    localStorage.setItem(getLocalStorageKey(`layer${index + 1}`), dataUrl);
                }
            });
        };

        const intervalId = setInterval(() => {
            if (!isCanvasModified) return;
            saveLayersToLocalStorage();
        }, 5000); // Save every 10 seconds

        return () => clearInterval(intervalId);
    }, [isPublished, isCanvasModified, canvasRefs, getLocalStorageKey]);

    // load from local storage every 10 seconds


    // Load the saved signature from local storage when the component mounts
    useEffect(() => {
        const savedSignature = localStorage.getItem('signature');
        if (savedSignature) {
            setLocalSignature(savedSignature);
            setSignature(savedSignature); // Set signature state with the saved signature
        }
    }, []);

    useEffect(() => {

        const shareDrawing = async () => {
            const ctx = mainContext.current;
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            contexts.current.forEach((layerCtx) => {
                ctx.drawImage(layerCtx.canvas, 0, 0);
            });
            if (!ctx) return;

            mainCanvasRef.current.toBlob(async (blob) => {
                const file = new File([blob], 'drawing.png', { type: 'image/png' });
                const shareData = {
                    files: [file],
                    //url: 'https://thedailydoodle.net', // Example URL, replace with your actual URL
                    text: "Today's Subject: " + dailySubject
                };
                if (navigator.share) {
                    try {
                        await navigator.share(shareData);
                        console.log('Drawing shared successfully');
                    } catch (error) {
                        console.error('Error sharing the drawing:', error);
                    }
                } else {
                    alert('Web Share API is not supported in your browser.');
                }
            });
        };

        myShareDrawingRef.current = shareDrawing;
        return () => {
            myShareDrawingRef.current = null;
        };
    }, [myShareDrawingRef, dailySubject]);

    useEffect(() => {
        mySaveDrawingRef.current = () => setShowSignaturePrompt(true);
        return () => {
            mySaveDrawingRef.current = null;
        };
    }, [mySaveDrawingRef]);


    useEffect(() => {
        const disableDefaultBehavior = (event) => {
            // Check if the event target is within the ColorPalette component
            if (event.target.closest('.helpClose') || event.target.closest('.confirm-dialog') || event.target.closest('.splash') || event.target.closest('.palette-z-index') || event.target.closest('.layer-selection') || event.target.closest('.navigation') || event.target.closest('.signature')) {
                return; // Allow default behavior for ColorPalette component
            }
            event.preventDefault();
        };

        document.addEventListener('touchstart', disableDefaultBehavior, { passive: false });
        document.addEventListener('touchmove', disableDefaultBehavior, { passive: false });
        document.addEventListener('touchend', disableDefaultBehavior, { passive: false });
        document.addEventListener('mousedown', disableDefaultBehavior, { passive: false });
        document.addEventListener('mousemove', disableDefaultBehavior, { passive: false });
        document.addEventListener('mouseup', disableDefaultBehavior, { passive: false });

        return () => {
            document.removeEventListener('touchstart', disableDefaultBehavior);
            document.removeEventListener('touchmove', disableDefaultBehavior);
            document.removeEventListener('touchend', disableDefaultBehavior);
            document.removeEventListener('mousedown', disableDefaultBehavior);
            document.removeEventListener('mousemove', disableDefaultBehavior);
            document.removeEventListener('mouseup', disableDefaultBehavior);
        };
    }, []);

    const drawMainCanvas = useCallback(() => {
        const ctx = mainContext.current;
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        contexts.current.forEach((layerCtx, i) => {

            if (i === activeLayer) {
                layerCtx.clearRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);
                if (i === 0) {
                    // Fill the first layer with white
                    layerCtx.fillStyle = 'white';
                    layerCtx.fillRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);
                }
                layerCtx.drawImage(beginDrawLayerContext.current.canvas, 0, 0);
                layerCtx.globalAlpha = alpha;
                if (eraseMode) layerCtx.globalCompositeOperation = 'destination-out'; // Set the composite operation to erase
                layerCtx.drawImage(workContext.current.canvas, 0, 0);
                if (eraseMode) layerCtx.globalCompositeOperation = 'source-over'; // Set the composite operation to erase
                layerCtx.globalAlpha = 1;
                ctx.drawImage(layerCtx.canvas, 0, 0);
            } else {
                if (i === 0) {
                    // Fill the first layer with white
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);
                }

                ctx.drawImage(layerCtx.canvas, 0, 0);
            }
        });
    }, [alpha, activeLayer, eraseMode]);

    const getTouchOffset = (nativeEvent) => {

        const rect = mainCanvasRef.current.getBoundingClientRect();
        const touch = nativeEvent.touches[0];
        //alert(rect.left);
        const offsetX = (touch.clientX - rect.left) / scale;
        const offsetY = (touch.clientY - rect.top) / scale;
        return { offsetX, offsetY };

    };

    const selectColorAtPosition = (event) => {
        const rect = mainCanvasRef.current.getBoundingClientRect();
        const touch = event.touches[0];

        const offsetX = (touch.clientX - rect.left) * (mainCanvasRef.current.width / rect.width);
        const offsetY = (touch.clientY - rect.top) * (mainCanvasRef.current.height / rect.height);

        const ctx = mainContext.current;
        const imageData = ctx.getImageData(offsetX, offsetY, 1, 1).data;
        const [r, g, b, a] = imageData;
        const rgbaColor = `rgba(${r},${g},${b},${a / 255})`;
        setColor(rgbaColor);
    };

    const startDrawing = (event) => {
        const { offsetX, offsetY } = getTouchOffset(event);
        if (sampleMode) {

            selectColorAtPosition(event);
            setSampleMode(false);
        }
        else {

            beginDrawLayerContext.current.clearRect(0, 0, beginDrawLayerContext.current.canvas.width, beginDrawLayerContext.current.canvas.height);
            beginDrawLayerContext.current.drawImage(contexts.current[activeLayer].canvas, 0, 0);

            if (event.touches.length === 2) {
                setTouchPoints([
                    { x: event.touches[0].clientX, y: event.touches[0].clientY },
                    { x: event.touches[1].clientX, y: event.touches[1].clientY }
                ]);
                return; //  Exit function if multi-touch is detected   
            }

            event.preventDefault();

            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.beginPath();

            if (lineMode === 'square' || lineMode === 'circle' || lineMode === 'straight' || lineMode === 'horizontal') {
                setLineStart({ x: offsetX, y: offsetY });
            } else {
                workContext.current.moveTo(offsetX, offsetY);
            }

            workContext.current.strokeStyle = color;
            workContext.current.lineWidth = brushSize;

            //workContext.current.globalAlpha = alpha;
            setLastPoint({ offsetX, offsetY });
            setIsDrawing(true);
            setIsCanvasModified(true);
        }
    };

    const draw = (event) => {

        if (event.touches.length === 2) return;
        event.preventDefault();
        if (!isDrawing) return;
        const { offsetX, offsetY } = getTouchOffset(event);
        const { offsetX: lastX, offsetY: lastY } = lastPoint;
        const midPointX = (lastX + offsetX) / 2;
        const midPointY = (lastY + offsetY) / 2;

        setHasMoved(true); // Set hasMoved flag to true

        if (lineMode === 'square') {
            const { x: startX, y: startY } = lineStart;
            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.strokeRect(startX, startY, offsetX - startX, offsetY - startY);

        } else if (lineMode === 'circle') {
            const { x: startX, y: startY } = lineStart;
            const radiusX = Math.abs(offsetX - startX) / 2;
            const radiusY = Math.abs(offsetY - startY) / 2;
            const centerX = startX + (offsetX - startX) / 2;
            const centerY = startY + (offsetY - startY) / 2;
            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.beginPath();
            workContext.current.ellipse(centerX, centerY, radiusX, radiusY, 0, 0, 2 * Math.PI);
            workContext.current.stroke();
        } else if (lineMode === 'straight') {
            const { x: startX, y: startY } = lineStart;
            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.beginPath();
            workContext.current.moveTo(startX, startY);
            workContext.current.lineTo(offsetX, offsetY);
            workContext.current.stroke();
        } else if (lineMode === 'horizontal') {
            const { x: startX, y: startY } = lineStart;
            const deltaX = Math.abs(offsetX - startX);
            const deltaY = Math.abs(offsetY - startY);

            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.beginPath();
            if (deltaX > deltaY) {
                // Draw horizontal line
                workContext.current.moveTo(startX, startY);
                workContext.current.lineTo(offsetX, startY);
            } else {
                // Draw vertical line
                workContext.current.moveTo(startX, startY);
                workContext.current.lineTo(startX, offsetY);
            }
            workContext.current.stroke();
        } else {
            workContext.current.quadraticCurveTo(
                lastX,
                lastY,
                midPointX,
                midPointY
            );
            workContext.current.stroke();
        }


        setLastPoint({ offsetX, offsetY });
        drawMainCanvas();
        console.log('draw', { offsetX, offsetY });
    };

    const endDrawing = (event) => {
        event.preventDefault();
        if (!isDrawing) return;

        const { offsetX, offsetY } = lastPoint;
        if (lineMode === 'squiggle' && !hasMoved && !recentResize) {
            workContext.current.clearRect(0, 0, workContext.current.canvas.width, workContext.current.canvas.height);
            workContext.current.beginPath();
            workContext.current.arc(offsetX, offsetY, brushSize / 2, 0, 2 * Math.PI);
            workContext.current.fillStyle = color;
            workContext.current.fill();
        }

        workContext.current.closePath();

        setIsDrawing(false);
        setHasMoved(false); // Reset hasMoved flag
        drawMainCanvas();
        saveHistory(); // Save history after drawing
        console.log('endDrawing');
    };

    const handleCombinedTouchMove = (event) => {
        draw(event);
        handleGestureChange(event);
    };

    const handleGestureChange = (e) => {
        clearTimeout(touchHoldTimerRef.current);
        e.preventDefault();
        if (e.touches.length > 1) {
            const newTouches = [
                { x: e.touches[0].clientX, y: e.touches[0].clientY },
                { x: e.touches[1].clientX, y: e.touches[1].clientY }
            ];
            const initialMidpoint = {
                x: (touchPoints[0].x + touchPoints[1].x) / 2,
                y: (touchPoints[0].y + touchPoints[1].y) / 2,
            };
            const newMidpoint = {
                x: (newTouches[0].x + newTouches[1].x) / 2,
                y: (newTouches[0].y + newTouches[1].y) / 2,
            };
            const initialDistance = Math.sqrt(
                Math.pow(touchPoints[0].x - touchPoints[1].x, 2) +
                Math.pow(touchPoints[0].y - touchPoints[1].y, 2)
            );
            const newDistance = Math.sqrt(
                Math.pow(newTouches[0].x - newTouches[1].x, 2) +
                Math.pow(newTouches[0].y - newTouches[1].y, 2)
            );
            const scaleChange = newDistance / initialDistance;
            setScale(scale * scaleChange);
            setRecentResize(true);

            const positionChange = {
                x: newMidpoint.x - initialMidpoint.x,
                y: newMidpoint.y - initialMidpoint.y,
            };
            setPosition({
                x: position.x + positionChange.x,
                y: position.y + positionChange.y
            });
            setTouchPoints(newTouches);
        }
    };

    useEffect(() => {
        setShowSubject(true)

    }, [setShowSubject]);


    useEffect(() => {
        const meta = document.createElement('meta');
        meta.name = "apple-mobile-web-app-capable";
        meta.content = "yes";
        document.head.appendChild(meta);

        // Cleanup on component unmount
        return () => {
            document.head.removeChild(meta);
        }
    }, []);




    useEffect(() => {
        const loadLayersFromLocalStorage = () => {
            //alert('loading...');
            const ctx = mainContext.current;
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

            const promises = contexts.current.map((layerCtx, i) => {
                return new Promise((resolve) => {
                    const dataUrl = localStorage.getItem(getLocalStorageKey(`layer${i + 1}`));
                    if (dataUrl) {
                        const img = new Image();
                        img.onload = () => {
                            layerCtx.clearRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);
                            layerCtx.drawImage(img, 0, 0);
                            ctx.drawImage(layerCtx.canvas, 0, 0);
                            resolve(); // Resolve the promise after the image has loaded
                        };
                        img.src = dataUrl;
                    } else {
                        resolve(); // Resolve the promise immediately if there's no dataUrl
                    }
                });
            });

            Promise.all(promises).then(() => {
                saveHistory(); // Call saveHistory after all images have loaded
            });
        };

        const fetchDailySubject = async () => {
            try {
                const response = await fetch(`https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com/getDailySubject`);
                const data = await response.json();
                console.log(data);
                if (data && data.subject) {
                    setDailySubject(data.subject); // Store the daily subject in the state
                }
            } catch (error) {
                console.error('Failed to fetch daily subject:', error);
            }
        };

        const getCurrentDateInCentralTime = () => {
            const currentDate = new Date().toLocaleString("en-US", { timeZone: "America/Chicago" }).split(',')[0];
            return currentDate;
        };

        const clearLocalStorageDrawings = () => {
            for (let i = 1; i <= 3; i++) {
                localStorage.removeItem(getLocalStorageKey(`layer${i}`));
            }
            // clear the layers

            // Clear the layers
            contexts.current.forEach((layerCtx) => {
                layerCtx.clearRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);
            });

            // Clear the main canvas
            mainContext.current.clearRect(0, 0, mainContext.current.canvas.width, mainContext.current.canvas.height);

            //drawMainCanvas();
        };



        const initializeCanvas = (canvas, index) => {
            canvas.width = window.innerWidth * 0.9 * 2;
            canvas.height = window.innerHeight * 0.7 * 2;
            canvas.style.width = `${window.innerWidth * 0.9}px`;
            canvas.style.height = `${window.innerHeight * 0.7}px`;
            const ctx = canvas.getContext('2d');
            ctx.lineWidth = 2;
            ctx.lineCap = 'round';
            ctx.scale(1, 1);
            contexts.current[index] = ctx;
        };

        canvasRefs.forEach((ref, index) => {
            initializeCanvas(ref.current, index);
        });

        const mainCanvas = mainCanvasRef.current;
        mainCanvas.width = window.innerWidth * 0.9 * 2;
        mainCanvas.height = window.innerHeight * 0.7 * 2;
        mainCanvas.style.width = `${window.innerWidth * 0.9}px`;
        mainCanvas.style.height = `${window.innerHeight * 0.7}px`;
        mainContext.current = mainCanvas.getContext('2d');
        mainContext.current.scale(1, 1);

        const workCanvas = workCanvasRef.current;
        workCanvas.width = window.innerWidth * 0.9 * 2;
        workCanvas.height = window.innerHeight * 0.7 * 2;
        workCanvas.style.width = `${window.innerWidth * 0.9}px`;
        workCanvas.style.height = `${window.innerHeight * 0.7}px`;
        workContext.current = workCanvas.getContext('2d');
        workContext.current.scale(2, 2);

        const beginDrawLayerCanvas = beginDrawLayerCanvasRef.current;
        beginDrawLayerCanvas.width = window.innerWidth * 0.9 * 2;
        beginDrawLayerCanvas.height = window.innerHeight * 0.7 * 2;
        beginDrawLayerCanvas.style.width = `${window.innerWidth * 0.9}px`;
        beginDrawLayerCanvas.style.height = `${window.innerHeight * 0.7}px`;
        beginDrawLayerContext.current = beginDrawLayerCanvas.getContext('2d');
        beginDrawLayerContext.current.scale(1, 1);

        // Initialize the off-screen canvas
        const offScreenCanvas = document.createElement('canvas');
        offScreenCanvas.width = window.innerWidth * 0.9 * 2;
        offScreenCanvas.height = window.innerHeight * 0.7 * 2;
        offScreenCanvas.style.width = `${window.innerWidth * 0.9}px`;
        offScreenCanvas.style.height = `${window.innerHeight * 0.7}px`;
        offScreenContext.current = offScreenCanvas.getContext('2d');
        beginDrawLayerContext.current.scale(1, 1);

        const currentDateInCentralTime = getCurrentDateInCentralTime();
        const lastVisitDateKey = getLocalStorageKey('lastVisitDate');
        const lastVisitDate = localStorage.getItem(lastVisitDateKey);

        // Determine whether to show the splash screen based on the last visit date
        if (!lastVisitDate || lastVisitDate !== currentDateInCentralTime) {

            setShowSplash(true);

            clearLocalStorageDrawings();
            setIsPublished(false);
            // Update the lastVisitDate in localStorage to the current date after showing the splash
            localStorage.setItem(lastVisitDateKey, currentDateInCentralTime);
        } else {
            loadLayersFromLocalStorage();
            setShowSplash(false);
        }

        fetchDailySubject(); // Call the function to fetch the daily subject

        // Load layers once on initial rende


    }, [guid, canvasRefs, getLocalStorageKey]);

    const handleSplashOkClick = () => {
        // Hide the splash screen
        setShowSplash(false);
    };

    function getBase64Image() {
        const canvas = mainCanvasRef.current;
        //drawMainCanvas();
        if (canvas) {
            return canvas.toDataURL("image/jpeg");
        }
        return null;
    }

    const saveDrawing = async (base64Image, userId, published, drawingSubject, signature) => {
        try {
            await fetch('https://ibt18xjvyj.execute-api.us-east-1.amazonaws.com', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    image: base64Image,
                    userId: userId,
                    published: published,
                    drawingSubject: drawingSubject,
                    signature: signature
                })
            });
        } catch (error) {
            console.error('Failed to save drawing:', error);
        }
    }




    // Handlers for brush size, alpha, and erase mode
    const handleBrushSizeChange = (size) => {
        const sizeMap = { 'XS': 2, 'S': 5, 'M': 10, 'L': 20, 'XL': 40 };
        setBrushSize(sizeMap[size]);
    };

    const handleAlphaChange = (newAlpha) => {
        setAlpha(newAlpha);
    };

    const handleLineModeChange = (lineMode) => {
        setLineMode(lineMode);
    };

    const handleEraseModeChange = (eraseMode) => {
        setEraseMode(eraseMode);
    }

    const handleClearCanavs = () => {
        setShowConfirm(true);
    }


    // Handle the user confirmation
    const handleConfirm = () => {

        clearLocalStorageAndReload();
        setShowConfirm(false);
    };

    const handleCancel = () => {
        setShowConfirm(false);
    };

    const undoLastAction = useCallback(() => {
        if (historyIndex <= 0) return; // No more history to undo
        const newHistoryIndex = historyIndex - 1;
        setHistoryIndex(newHistoryIndex);

        const previousCanvases = history[newHistoryIndex];
        const offScreenCtx = offScreenContext.current;

        // Clear the off-screen canvas
        offScreenCtx.clearRect(0, 0, offScreenCtx.canvas.width, offScreenCtx.canvas.height);

        const imagePromises = previousCanvases.map((dataUrl, index) => {
            return new Promise(resolve => {
                const img = new Image();
                img.onload = () => {
                    resolve({ img, index });
                };
                img.src = dataUrl;
            });
        });

        Promise.all(imagePromises).then(images => {
            images.sort((a, b) => a.index - b.index); // Sort images by index

            images.forEach(({ img, index }) => {
                const layerCtx = contexts.current[index];
                layerCtx.clearRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);
                if (index === 0) {
                    // Fill the first layer with white
                    layerCtx.fillStyle = 'white';
                    layerCtx.fillRect(0, 0, layerCtx.canvas.width, layerCtx.canvas.height);
                }

                layerCtx.drawImage(img, 0, 0);
                offScreenCtx.drawImage(img, 0, 0);
            });

            // Transfer the off-screen canvas to the visible canvas after all images have loaded
            const mainCtx = mainContext.current;
            mainCtx.clearRect(0, 0, mainCtx.canvas.width, mainCtx.canvas.height);
            mainCtx.drawImage(offScreenCtx.canvas, 0, 0);

            // Optional: Call drawMainCanvas if you need to draw additional elements
            // drawMainCanvas();
        });
    }, [history, historyIndex, contexts]);

    const saveHistory = useCallback(() => {
        const canvases = canvasRefs.map(ref => ref.current.toDataURL());
        setHistory(prevHistory => {
            const newHistory = [...prevHistory.slice(0, historyIndex + 1), canvases];
            if (newHistory.length > 20) newHistory.shift(); // Keep history size up to 20
            return newHistory;
        });
        setHistoryIndex(prevIndex => Math.min(prevIndex + 1, 19)); // Increment index and limit to 19
    }, [canvasRefs, historyIndex]);


    const canvasStyle = {
        border: '1px solid black',
        margin: '10px',
        touchAction: 'none',
        backgroundColor: 'white',
        transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`
    };

    const hiddenCanvasStyle = {
        transform: `translate(${position.x}px, ${position.y}px) scale(${scale})`,
        display: 'none'
    };

    return (

        <div style={{ textAlign: 'center' }}>


            {/* Color Palette */}
            <ColorPalette
                color={color}
                setColor={setColor}
                onBrushSizeChange={handleBrushSizeChange}
                onAlphaChange={handleAlphaChange}
                onUndo={undoLastAction}
                onLineModeChange={handleLineModeChange}
                onEraseModeChange={handleEraseModeChange}
                sampleMode={sampleMode}
                setSampleMode={setSampleMode}
                onClearCanvas={handleClearCanavs}
            />

            <div className="container" style={{ position: 'relative' }}>

                <canvas
                    ref={mainCanvasRef}
                    style={canvasStyle}
                    onTouchStart={startDrawing}
                    onTouchMove={handleCombinedTouchMove}
                    onTouchEnd={endDrawing}
                    onTouchCancel={endDrawing}
                />
                {canvasRefs.map((ref, index) => (
                    <canvas
                        ref={ref}
                        key={index}
                        style={hiddenCanvasStyle}

                    />
                ))}
                <canvas
                    ref={workCanvasRef}
                    style={hiddenCanvasStyle}

                />
                <canvas
                    ref={beginDrawLayerCanvasRef}
                    style={hiddenCanvasStyle}
                />
                <canvas
                    ref={offScreenCanvasRef}
                    style={hiddenCanvasStyle}
                />
            </div>
            <div className="layer-selection" style={{
                position: 'fixed',
                top: '50%',
                right: '0',
                transform: 'translateY(-50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '0px',
                zIndex: 1
            }}>
                <button
                    style={{
                        fontSize: '16px',
                        backgroundColor: activeLayer === 2 ? 'lightblue' : 'white',
                        color: 'black',
                        border: '1px solid black',
                        width: '40px', // Set a fixed width
                        boxSizing: 'border-box', // Include border in width calculation
                        marginBottom: '-1px' // Avoid double border
                    }}
                    onClick={() => setActiveLayer(2)}
                >
                    L3
                </button>
                <button
                    style={{
                        fontSize: '16px',
                        backgroundColor: activeLayer === 1 ? 'lightblue' : 'white',
                        color: 'black',
                        border: '1px solid black',
                        width: '40px', // Set a fixed width
                        boxSizing: 'border-box', // Include border in width calculation
                        marginBottom: '-1px' // Avoid double border
                    }}
                    onClick={() => setActiveLayer(1)}
                >
                    L2
                </button>
                <button
                    style={{
                        fontSize: '16px',
                        backgroundColor: activeLayer === 0 ? 'lightblue' : 'white',
                        color: 'black',
                        border: '1px solid black',
                        width: '40px', // Set a fixed width
                        boxSizing: 'border-box' // Include border in width calculation
                    }}
                    onClick={() => setActiveLayer(0)}
                >
                    L1
                </button>
            </div>

            {/* Buttons Container */}

            {/* Show confirmation dialog */}
            {showConfirm && (
                <ConfirmDialog
                    message="Are you sure you want to reset the canvas?"
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                />
            )}


            {showSplash && (
                <div class="splash" style={{
                    position: 'fixed', // Changed to 'fixed' to ensure it's centered relative to the viewport
                    top: '50%',
                    left: '3%',
                    right: '3%',
                    width: '94%', // Ensures the content has the correct width
                    border: '1px solid black',
                    backgroundColor: 'rgba(255, 255, 255, 1)', // White with slight transparency
                    display: 'flex',
                    flexDirection: 'column', // Stack elements vertically
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000, // Ensure it's on top of other content
                    transform: 'translateY(-50%)', // Vertically center the splash screen
                    padding: '20px', // Add some padding around the content
                    boxSizing: 'border-box', // Include padding and border in the element's total width and height
                }}>
                    <div >
                        <p>Welcome to <b>The Daily Doodle!</b></p>
                        <p> Today's inspiration is "<u>{dailySubject}</u>." Express your creativity by doodling on this theme. Once you're satisfied with your masterpiece, hit the "Publish" button to share your artwork and explore how fellow doodlers have interpreted the subject. </p>
                        <p>Don't forget to check back in to see new doodles as they're added throughout the day. And remember, a new subject awaits your creativity tomorrow. Join us again for another day of doodling!</p>
                    </div>
                    <button onClick={handleSplashOkClick} style={{ marginTop: '20px', fontSize: '20px' }}>OK</button>
                </div>
            )}

            {showSignaturePrompt && (
                <div class='signature' style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '20px',
                    border: '1px solid black',
                    zIndex: 1001
                }}>
                    <p>Sign your work or remain anonymous.</p>
                    <input
                        type="text"
                        value={signature}
                        onChange={(e) => setSignature(e.target.value)}
                        placeholder="Enter your signature"
                        style={{
                            fontSize: '16px', // Prevent zoom on iPhone
                            width: '100%', // Make the input full width
                            padding: '1px', // Add padding for better visibility
                            marginBottom: '10px' // Add margin below the input
                        }}
                    />
                    <div>
                        <button onClick={handleSignatureSubmit}>Publish</button>
                    </div>
                </div>
            )}
        </div>

    );
};

export default DrawingCanvas;